import React from "react";
import Helmet from "react-helmet";
import { Mutation } from "react-apollo";
import App from "./_app";
import LoginForm from "../forms/login";
import Container from "../components/Container";
// import { LOGIN } from "..//queries/account";
import { LOGIN } from "../queries/account";
import Alert from "../components/Alert";
import Wrap from "../components/Wrap";

const detectIE = () => {
  if (typeof window === "undefined") return true;
  let ua = window.navigator.userAgent;
  return ua.includes("Trident") || ua.includes("MSIE") || ua.includes("Edge");
};

const Login = () => (
  <App>
    <Helmet>
      <title>FlexTime Login</title>
    </Helmet>
    <Wrap muted>
      <Container>
        <h1>Log into FlexTime</h1>
        {detectIE() ? (
          <p>
            Please download a modern browser like Chrome or FireFox to access
            this portion of the website. Alternatively, you can login through
            your mobile device.
          </p>
        ) : (
          <Mutation mutation={LOGIN}>

            {(query, { error }) => (
              <div>

                <LoginForm action={query} />
                {JSON.stringify(error)}
                {error && (
                  <Alert timeout={false}>
                    The email or password entered is incorrect. On your 10th
                    attempt, your account will be suspended. If the requested
                    account has not yet been setup for online usage, you will
                    receive an email with further instructions.
                  </Alert>
                )}
              </div>
            )}
          </Mutation>
        )}
      </Container>
    </Wrap>
  </App>
);

export default Login;
