import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import Submit from "../components/Submit";

const LoginForm = ({ action }) => (
  <Formik
    initialValues={{
      email: "",
      password: "",
    }}
    onSubmit={(variables) =>
      action({ variables }).then(({ data }) => {
        Cookies.set("access_token", data.login.access);
        Cookies.set("refresh_token", data.login.refresh);
        window.location.replace("/flextime");
      })
    }
    render={() => (
      <Form>
        <label>
          Email *
          <Field name="email" type="string" required />
        </label>

        <label>
          Password *
          <Field name="password" type="password" required />
        </label>

        <Submit text="Login" />
        <Link style={{ marginLeft: "1rem" }} to="/password">
          Reset Password
        </Link>
      </Form>
    )}
  />
);

LoginForm.propTypes = {
  action: PropTypes.func,
};

export default LoginForm;
